import styles from "./Header.module.scss";
import { NavLink } from "react-router-dom";

export const Nav = ({admin}:any) => {
    return (
        <ul className="nav nav-pills">
            <li className="nav-item">
                <NavLink
                    to='/'
                    className={({ isActive, isPending }) => {
                        let st = '';
                        st += isActive? 
                        styles.nav_link_active
                        : isPending? 
                        styles.nav_link_pending
                        : ''
                        st += ' nav-link ' + styles.nav_link
                        return st;
                        }
                    }>Главная</NavLink>
            </li>
            
            <li className="nav-item">
                <NavLink
                    to='/features'
                    className={({ isActive, isPending }) => {
                        let st = '';
                        st += isActive? 
                        styles.nav_link_active
                        : isPending? 
                        styles.nav_link_pending
                        : ''
                        st += ' nav-link ' + styles.nav_link
                        return st;
                        }
                    }>Услуги</NavLink>
            </li>
            {
                admin?
                <>
                    <li className="nav-item">
                        <NavLink
                            to='/adminka'
                            className={({ isActive, isPending }) => {
                                let st = '';
                                st += isActive? 
                                styles.nav_link_active
                                : isPending? 
                                styles.nav_link_pending
                                : ''
                                st += ' nav-link ' + styles.nav_link
                                return st;
                                }
                            }>Админка</NavLink>
                    </li>
                </>
                :null
            }
        </ul>
    )
}

const Header = ({admin}:any) => {
    return (
        <header className="py-3">
            <div className="row">
                <div className={["col-xl-3","col"].join(' ')}>
                    <NavLink to={'/'}><img src="/logo1.jpg" alt="logo" width={300} className={styles.logo}></img></NavLink>
                </div>
                <div className="col-xl-1"></div>
                <div className="col-xl-4 col justify-content-center d-flex">
                    <Nav admin={admin}></Nav>
                </div>
            </div>
            
        </header>
    );
}

export default Header;