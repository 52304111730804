import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import SideBar from '../SideBar'
import styles from './layout.module.css'

export default function Layout({ children, admin }) {
    return (
        <div className={`${styles.content} container`}>
            <button className={"btn " + styles.sideBar_btn} type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
            </svg>
            </button>
            <SideBar header='Навигация' admin={admin}></SideBar>
            <Header admin={admin}></Header>
            <main>{children}</main>
            <Footer></Footer>
        </div>
    )
}

export function AdminLayout({ children, admin }) {
  	return (
    <div className='container'>
        <button className={"btn d-block " + styles.sideBar_btn} type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
        </svg>
        </button>
        <SideBar header='Навигация' admin={admin}></SideBar>
        <main className={styles.content}>{children}</main>
    </div>
  )
}