import { useEffect, useState } from "react";
import MyCarousel from "../../components/Carousel/MyCarousel";
import Layout from '../../components/layout';
import st from './Main.module.scss';
import Spinner from "../../components/Spinner/Spinner";
import { Map, Placemark } from "@pbe/react-yandex-maps";
import Calendar from "../../components/Calendar/Calendar";
import { TimeList, TimeListPlug } from "../../components/TimeList/TimeList";
import { useSelector } from "react-redux";
interface MainProps {
    admin: boolean;
}

interface Table {
    date: object;
    time: object;
    taken: object;
}

const Main = ({admin}:MainProps) => {
    const [process, setProcess] = useState<boolean>(false);
    const dateSelected = useSelector((state:{date_selected:string}) => state.date_selected);
    const selectedDateTime = useSelector((state:{date_time:number}) => state.date_time);
    const [availableTimes, setTimes] = useState<string[] | null>(null);
    const [timesLoading, setTimesLoading] = useState<boolean>(false);
    const [dt_table, setTable] = useState<Table>()
    useEffect(() => {
        (async () => {
            setTable(
                await fetch("http://web-smile.ru/api/json/times")
                .then(response => response.json())
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setTimesLoading(false);
                    setProcess(false);
                })
            )
        })()
    }, [])
    useEffect(() => {
        setTimes(null);
        (async () => {
            if (selectedDateTime == null || !dt_table) return;
            setProcess(true);
            setTimesLoading(true);
            const selectedDate = new Date(selectedDateTime);
            selectedDate.setHours(0,0,0)
            const times = Object.values(dt_table.time).filter((value, idx) => {
                try {
                    return new Date(dt_table.date[idx]).toDateString() == selectedDate.toDateString() && !dt_table.taken[idx]
                } catch {
                    return false
                }   
            })
            setTimes(times)
            setProcess(false);
            setTimesLoading(false);
        })()
    }, [dateSelected]);

    return (
        <div className={st.main}>
            <Layout admin={admin}>
                {
                    process?
                    <Spinner/>
                    :null
                }
                <div className={st.main}>
                    <div className="mt-5"></div>
                    <MyCarousel></MyCarousel>
                    <div className="row mt-5">
                        <div className="col col-xl-6">
                            {
                                selectedDateTime?
                                    availableTimes?
                                    <>
                                        {
                                            availableTimes.length === 0 ?
                                            <>
                                                <h3>Нет записи</h3>
                                                <span>Данные на сайте могут отличаться от истинных,
                                                    уточняйте доступное время по телефону</span>
                                            </>
                                            :
                                            <>
                                                <h3>Доступное время</h3>
                                                <TimeList times={availableTimes}></TimeList>
                                            </>
                                        }
                                    </>
                                    :
                                    <>
                                        <TimeListPlug/>
                                    </>
                                :
                                <div className='col col-xl-3'>
                                    <h3>Выберите дату</h3>
                                </div>
                            }
                        </div>
                        <div className="col col-xl-6 mt-5">
                            <div className={timesLoading?st.overlay:''}>
                                <Calendar monthsCount={4}></Calendar>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className={"col-12 col-lg-12 col-sm-12 mt-5 "}>
                            <h4>Как добраться</h4>
                            <Map defaultState={{ center: [53.475629, 40.211170], zoom: 18 }} className={st.map}>
                                <Placemark defaultGeometry={[53.475629, 40.211170]} />
                            </Map>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    );
};

export default Main;