import { NavLink } from "react-router-dom";
import Layout from "../../components/layout";

const NotMatch = () => {
    return (
        <Layout admin={false}>
            <h3>Увы, такой страницы нет</h3>
            <NavLink to={'/'}>Перейти на главную</NavLink>
        </Layout>
    )
};

export default NotMatch;