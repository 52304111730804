import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeTime } from "../../redux/actions";
import st from './TimeItem.module.scss';

interface TimeObj {
    value: string
}

interface Props {
    time: TimeObj;
    selected?: boolean;
}

const TimeItem = ({time, selected=false}:Props) => {
    const dispatch = useDispatch();
	const selTime = useSelector((state:{time_selected:string}) => state.time_selected);
    let styles = `${st['timelist-item']}`;
    let timeEqual = selTime == time.value
    styles += selected?` ${st['timelist-item-selected']}`:'';
    styles += timeEqual?` ${st['timelist-item-active']}`:'';
    const hanlder = useCallback(() => {
        dispatch(changeTime(time.value));
    }, [time.value])
    return (
        <div 
        onClick={hanlder} 
        className={styles}>
            {time.value}
        </div>
    )
}

export default TimeItem;