import {combineReducers} from 'redux'
import {CHANGE_DATE_TIME, CHANGE_SERVICES, CHANGE_USER, CHANGE_SCHEDULE, CHANGE_TIME, CHANGE_DATE} from './types'

interface Action {
    type: string;
    payload: string | number | boolean;
}

function dateTimeReducer(state=null, action: Action) {
    if (action.type === CHANGE_DATE_TIME) {
        return action.payload;
    } 
    return state
}

function timeReducer(state=false, action: Action) {
    if (action.type === CHANGE_TIME) {
        return action.payload;
    } 
    return state
}

function dateReducer(state=false, action: Action) {
    if (action.type === CHANGE_DATE) {
        return action.payload;
    } 
    return state
}

function servicesReducer(state=[], action: Action) {
    if (action.type === CHANGE_SERVICES) {
        return action.payload;
    } 
    return state
}

function scheduleReducer(state=null, action: Action) {
    if (action.type === CHANGE_SCHEDULE) {
        return action.payload;
    } 
    return state
}

function userReducer(state=null, action: Action) {
    if (action.type === CHANGE_USER) {
        return action.payload;
    } 
    return state
}

export const rootReducer = combineReducers({
  services: servicesReducer,
  schedule: scheduleReducer,
  date_time: dateTimeReducer,
  time_selected: timeReducer,
  date_selected: dateReducer,
  user: userReducer
})