import Layout from "../../components/layout";
import styles from './features.module.scss'
import { useEffect, useState} from 'react'
import Spinner from "../../components/Spinner/Spinner";


interface DescProps {
    key: string;
    idx?: number;
    cells: (string|JSX.Element)[];
    desc: string | JSX.Element | null;
}


export const Row = ({cells, idx=-1, desc, key}:DescProps) => {
    const [desc_on, setDesc] = useState<boolean>(false);
    const [animate, setAnimate] = useState<boolean>(false);
    return (
        <>
            <tr key={key}>
                {
                    cells.map(cell => (
                        <td className="text-start">{cell}</td>
                    ))
                }
                {
                    desc !== undefined ?
                    <td>
                        <div className={styles.cell_content}>
                            <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="24" 
                            height="24" 
                            fill="currentColor" 
                            className={["bi bi-caret-down-square-fill", styles.desc_btn,  animate ? desc_on ? styles.desc_btn_rotate_down : styles.desc_btn_rotate_top : ''].join(' ')} 
                            viewBox="0 0 16 16"
                            onClick={() => {
                                setDesc(!desc_on);
                                setAnimate(true);
                            }}
                            >
                                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm4 4a.5.5 0 0 0-.374.832l4 4.5a.5.5 0 0 0 .748 0l4-4.5A.5.5 0 0 0 12 6H4z"/>
                            </svg>
                        </div>
                    </td>
                    :
                    null
                }
            </tr>
            {
                desc_on ?  
                <tr className={styles.desc}>
                    <td colSpan={cells.length + 1}>{desc}</td>
                </tr> : null
            }
        </>
    )
}

export interface FeaturesProps {
    admin: boolean;
}

const Features = ({admin}:FeaturesProps) => {
    const [services, setServices] = useState<[]>([]);
    const [header, setHeader] = useState<string[]>([]);
    useEffect(() => {
        (async () => {
            await fetch("http://web-smile.ru/api/json/prices")
            .then(response => response.json())
            .then(((res) => {
                setServices(res)
                setHeader(Object.keys(res[0]))
            }))
            .catch((error) => {
                console.error(error);
                return
            })
            
        })()
    }, [])


    return (
        <Layout admin={admin}>
            {
                services.length === 0 ?
                <>
                    <Spinner></Spinner>
                    <div className={styles.table_plug}></div>
                </>
                : 
                <div className={styles.wrapper}>
                    <table className="table table-borderless">
                        <thead>
                            <tr>
                                {
                                    header.map((column:any, i) => (
                                        <th scope="col">{column}</th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                services.map((record:any, i) => (
                                    <Row 
                                    cells={Object.values(record)}
                                    idx = {i}
                                    desc={record.desc}
                                    key={String(Object.values(record)[0])}
                                    ></Row>
                                ))
                            }

                        </tbody>
                    </table>
                </div>
            }
        </Layout>
    );
};

export default Features;