import { NavLink } from "react-router-dom";
import MySlider, { SliderItem } from "../MySlider/MySlider";
import ss from './MyCarousel.module.scss';

const MyCarousel = () => {
    return (
        <div className={ss.mycarousel}>
            <div className={ss.surface}>
                <h4>Запись</h4>
                <span className="fw-bold">ПН-ПТ:</span><br/>
                <span>9:00 - 16:00</span><br/>
                По телефону:<br/>
                <a className="fs-4" href="tel:+89056910101">+7 (905) 691-01-01</a><br/>
                <h4>Адрес</h4>
                р.п. Александро-Невский, ул. Почтовая, д.30<br/>
                53.475629, 40.211170
            </div>
            <div className={ss.inner}>
                <MySlider auto={true}>
                    <SliderItem>
                        <img src="/img/slider_0.jpg" alt=""></img>
                    </SliderItem>
                    <SliderItem>
                        <img src="/img/slider_1.jpg" alt=""></img>
                    </SliderItem>
                    <SliderItem>
                        <img src="/img/slider_2.jpg" alt=""></img>
                    </SliderItem>
                </MySlider>
            </div>
            
        </div>
    )
}

export default MyCarousel;