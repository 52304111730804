import { Nav } from "../Header/Header";
import st from './SideBar.module.scss'

interface Props {
    header: string;
    admin: boolean;
}

const SideBar = ({header, admin=false}:Props) => {
    return (
        <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex={-1} id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasScrollingLabel">{header}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className={"offcanvas-body " + st.sidebar}>
                <Nav admin={admin}></Nav>
            </div>
        </div>
    );
}

export default SideBar;