import { useCallback, useEffect, useState } from 'react';
import TimeItem from '../TimeItem/TimeItem';
import styles from "./TimeList.module.css";
import { useSelector } from 'react-redux';

interface Props {
    times: string[];
    selected?: number[];
}

export const TimeList = ({times, selected = []}:Props) => {
    const extraCells = [];
    let i = 6;
    while (i - times.length > 0) {
        extraCells.push(<div className='col-5 col-md m-1'></div>)
        i--;
    }
    return (
        <div className="time_list">
            <div className='row mt-5 justify-content-center'>
                {
                !times?
                [1,2,3,4,5].map( () => <div className={styles.time_plug + ' col-5 col-md m-1'}></div>)
                :
                times.length !== 0 ?
                times.map( (time, index) => (<div className='col-5 col-md-2 m-1'>
                    <TimeItem 
                    key={index} 
                    time={{value: time}} 
                    selected={selected.indexOf(index) !== -1}
                    />
                    </div>))
                :null
                }   
                {
                    extraCells
                }
            </div>
        </div>
    )
}

export const TimeListPlug = () => {
    return (
        <div className="time_list">
            <div className='row mt-5'>
                {
                    [1,2,3,4,5].map( () => <div className={styles.time_plug + ' col'}></div>)
                }
            </div>
        </div>
    )
    
}