import DateTable from "../DateTable/DateTable";
import MySlider, { SliderItem } from "../MySlider/MySlider";

import styles from './Calendar.module.scss';


interface Props {
    monthsCount:number;
}

const Calendar = ({monthsCount}:Props) => {
    const monthsNames = ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'];
    let now = new Date();
    now.setDate(1);
    const tables = [];
    for (let i = 0; i < monthsCount; i++) {
        tables.push(<SliderItem>
        <DateTable 
        year = {now.getFullYear()}
        month = {now.getMonth()}
        monthName = {monthsNames[now.getMonth()]}
        ></DateTable></SliderItem>)
        now.setMonth(now.getMonth() + 1, 1)
    }

    return (
        <div className={`${styles.calendar}`}>
            <MySlider>
                {
                    tables
                }
            </MySlider>
        </div>
    )   
}

export default Calendar;