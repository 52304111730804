const Footer = () => {
    return (
        <footer className="py-3 my-4 mt-10">
            <ul className="nav justify-content-center border-bottom pb-3 mb-3">
                {/* <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">Документы</a></li> */}
            </ul>
            <p className="text-center text-muted">© 2024 ООО "Улыбка"</p>
        </footer>
    )
};

export default Footer;