
import Main from './pages/Main';
import Features from './pages/features';
import {
  BrowserRouter,
  Route,
  Link,
  Routes
} from "react-router-dom";
import { useEffect, useState } from 'react';
import st from './App.module.css'
import { YMaps } from '@pbe/react-yandex-maps';
import NotMatch from './pages/NotMatch/NotMatch';

const App = () => {
    const [adminStatus, setAdmin] = useState(false)

    
    return (
      <div className={st.app}>
        <YMaps>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Main admin = {adminStatus}/>}></Route>
              <Route path="/features" element={<Features admin = {adminStatus}/>}></Route>
              <Route path="*" element={<NotMatch/>}></Route>
            </Routes>
          </BrowserRouter>
        </YMaps>
      </div>
    )
}

export default App;