import {CHANGE_DATE_TIME, CHANGE_USER, CHANGE_SERVICES, CHANGE_SCHEDULE, CHANGE_TIME, CHANGE_DATE} from './types'

export function changeDateTime(newDate:number | null) {
  return {
    type: CHANGE_DATE_TIME,
    payload: newDate
  }
}

export function changeTime(newStatus:string) {
  return {
    type: CHANGE_TIME,
    payload: newStatus
  }
}

export function changeDate(newStatus:string) {
  return {
    type: CHANGE_DATE,
    payload: newStatus
  }
}

export function changeServices(newData:[]) {
  return {
    type: CHANGE_SERVICES,
    payload: newData
  }
}

export function changeSchedule(newData:any) {
  return {
    type: CHANGE_SCHEDULE,
    payload: newData
  }
}

export function changeUser(newUser:any) {
  return {
    type: CHANGE_USER,
    payload: newUser
  }
}